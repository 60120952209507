import {Component, Vue} from "vue-property-decorator";
@Component
class ObjectiveNotificationMixin extends Vue{
  get unreadObjectiveCommentNotification(){
    return this.$store.state.me.notifications.data.filter((noti: any) => {
      return noti.read_at == null && !!noti.message.message_detail_id;
    });
  }

  getUnreadComment(id: string){
    if(this.unreadObjectiveCommentNotification.length > 0 && this.unreadObjectiveCommentNotification.some((obj: any) => obj.message.message_detail_id === id)){
      const index = this.unreadObjectiveCommentNotification.findIndex((noti: any) => noti.message.message_detail_id === id);
      return this.unreadObjectiveCommentNotification[index].id;
    }
    return null;
  }
}

export {ObjectiveNotificationMixin}